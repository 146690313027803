<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200">
      <v-card v-if="resolutionScreen >= 400">
        <v-card-title :style="$vuetify.theme.dark ? 'color: white; font-size: 20px;' : 'color: black; font-size: 20px;'" class="font-weight-black">
          ที่อยู่ข้อมูลในการออกใบกำกับภาษี (Address Information Tax Invoices)
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-form v-model="valid" class="ma-1 mr-2 ml-2">
            <v-layout row wrap justify-center>
              <v-flex xs12 sm12 md12 lg12>
                <v-container fluid>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12>
                      <p style="font-size: 20px; color:#1279BC;" class="font-weight-black">ข้อมูลผู้เสียภาษี (Taxpayer Information)</p>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs5 sm5 md2 lg2>
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เลขประจำตัวผู้เสียภาษี<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Tax Identification Number</span>
                      </div>
                    </v-flex>
                    <v-flex xs7 sm7 md4 lg4>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="tax_num"
                        label="เลขประจำตัวผู้เสียภาษี 13 หลัก / Identification Number"
                        maxlength="13"
                        :rules="requiredtax_num"
                        :error-messages="tax_numError"
                        required
                        @input="fn_search_taxid(), $v.tax_num.$touch()"
                        @blur="$v.tax_num.$touch()"
                        @click:clear="fn_cleardata()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters>
                    <v-col xs="5" sm="5" md="2" lg="2">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ประเภทนิติบุคคล(ไทย)<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Type Business(TH)</span>
                      </div>
                    </v-col>
                    <v-col xs="7" sm="7" md="4" lg="4">
                      <v-select
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="typebusiness"
                        :items="listtypebusiness"
                        label="ประเภทนิติบุคคล(ไทย) / Type Business(TH)"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="typebusinessError"
                        required
                        @input="fn_checktypebizth(), $v.typebusiness.$touch()"
                        @blur="$v.typebusiness.$touch()"
                      ></v-select>
                    </v-col>
                    <v-col xs="5" sm="5" md="2" lg="2">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชื่อผู้ประกอบการ(ไทย)<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Business Name(TH)</span>
                      </div>
                    </v-col>
                    <v-col xs="7" sm="7" md="4" lg="4">
                      <v-text-field
                        clearable
                        @click:clear="clearbtnnameth()"
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_business_th"
                        label="ชื่อผู้ประกอบการ(ไทย) / Business Name(TH)"
                        :rules="requiredname"
                        :error-messages="name_business_thError"
                        required
                        @input="fn_name_th(), $v.name_business_th.$touch()"
                        @blur="$v.name_business_th.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-layout>
                    <v-flex xs5 sm5 md3 lg3>
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย)</span><br>
                        <span class="font-weight-black">Tax Invoice Name(TH)</span>
                      </div>
                    </v-flex>
                    <v-flex xs7 sm7 md9 lg9>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_invoice_th"
                        label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Tax Invoice Name (TH)"
                        @click="fn_name_th"
                        
                        
                        
                        
                      ></v-text-field>
                      <!-- :rules="requiredname_th" -->
                      <!-- :error-messages="name_invoice_thError" -->
                      <!-- required -->
                      <!-- @input="$v.name_invoice_th.$touch()" -->
                      <!-- @blur="$v.name_invoice_th.$touch()" -->
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs5 sm5 md3 lg3>
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ)</span><br>
                        <span class="font-weight-black">Tax Invoice Name(Eng)</span>
                      </div>
                    </v-flex>
                    <v-flex xs7 sm7 md9 lg9>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_invoice_eng"
                        label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Tax Invoice Name (Eng)"
                        @click="fn_name_eng"
                        
                        
                        
                      ></v-text-field>
                      <!-- :rules="requirednameeng" -->
                      <!-- :error-messages="name_invoice_engError" -->
                      <!-- required -->
                      <!-- @input="$v.name_invoice_eng.$touch()" -->
                      <!-- @blur="$v.name_invoice_eng.$touch()" -->
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-n5">
                    <v-flex xs2 sm2 md1 lg1>
                      <div class="mt-4 ml-1">
                        <span class="font-weight-black">สาขา<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Branch</span>
                      </div>
                    </v-flex>
                    <v-flex xs10 sm10 md11 lg11>
                      <v-radio-group v-model="radios" mandatory @change="fn_radiobranch">
                        <v-radio label="สำนักงานใหญ่ (Head office)" value="สำนักงานใหญ่"></v-radio>
                        <v-radio label="สาขาอื่นๆ (Other branches)" value="สาขาอื่นๆ"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs2 sm2 md1 lg1>
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">รหัสสาขา</span><br>
                        <span class="font-weight-black">Branch No</span>
                      </div>
                    </v-flex>
                    <v-flex xs4 sm4 md3 lg3>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="branch_no"
                        :disabled="radios === 'สำนักงานใหญ่'"
                        label="รหัสสาขา / Branch No"
                        maxlength="5"
                        :rules="requiredbranch_no"
                        :error-messages="branch_noError"
                        required
                        @input="$v.branch_no.$touch()"
                        @blur="$v.branch_no.$touch()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm2 md1 lg1>
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชื่อสาขา</span><br>
                        <span class="font-weight-black">Branch Name</span>
                      </div>
                    </v-flex>
                    <v-flex xs4 sm4 md7 lg7>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="branch_name"
                        :disabled="radios === 'สำนักงานใหญ่'"
                        label="ชื่อสาขา / Branch Name"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="branch_nameError"
                        required
                        @input="$v.branch_name.$touch()"
                        @blur="$v.branch_name.$touch()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อีเมล<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Email</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="email_business"
                        label="อีเมล* / Email"
                        :rules="requiredemail_biz"
                        :error-messages="email_businessError"
                        required
                        @input="$v.email_business.$touch()"
                        @blur="$v.email_business.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เบอร์มือถือ<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Mobile No</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="phone_number_biz"
                        label="เบอร์มือถือ* / Mobile No"
                        maxlength="10"
                        :rules="requiredTextphone"
                        :error-messages="phone_number_bizError"
                        required
                        @input="$v.phone_number_biz.$touch()"
                        @blur="$v.phone_number_biz.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เบอร์โทรศัพท์<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Telephone</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="tel_biz"
                        maxlength="10"
                        label="เบอร์โทรศัพท์* / Telephone"
                        ></v-text-field>
                        <!-- :rules="requiredTexttelephone"
                        :error-messages="tel_bizError"
                        required
                        @input="$v.tel_biz.$touch()"
                        @blur="$v.tel_biz.$touch()" -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 class="pa-1">
                <v-container fluid>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12>
                      <p style="font-size: 20px; color:#1279BC;" class="font-weight-black">ข้อมูลที่อยู่ (Address)</p>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters>
                  <!-- <v-layout> -->
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">รหัสไปรษณีย์<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Post Code</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="postcode"
                        label="รหัสไปรษณีย์ / Post Code"
                        maxlength="5"
                        :rules="requiredpostcode"
                        :error-messages="postcodeError"
                        required
                        @input="$v.postcode.$touch()"
                        @blur="$v.postcode.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">จังหวัด<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Province</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-autocomplete
                        ref="province"
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="province"
                        :items="listprovince"
                        label="จังหวัด / Province"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="provinceError"
                        required
                        @input="$v.province.$touch()"
                        @blur="$v.province.$touch()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อำเภอ/เขต<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">District</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-autocomplete
                        ref="district"
                        outlined
                        dense
                        clearable
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="district"
                        :items="listdistrict"
                        label="อำเภอ/เขต / District"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="districtError"
                        required
                        @input="$v.district.$touch()"
                        @blur="$v.district.$touch()"
                      ></v-autocomplete>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ตำบล/แขวง<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Sub-distric</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-autocomplete
                        ref="subdistrict"
                        outlined
                        dense
                        clearable
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="subdistrict"
                        :items="listsubdistrict"
                        label="ตำบล/แขวง / Sub-distric"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="subdistrictError"
                        required
                        @input="$v.subdistrict.$touch()"
                        @blur="$v.subdistrict.$touch()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เลขที่อยู่<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Number</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="noaddress"
                        label="เลขที่อยู่ / Number"
                        :rules="requirednoaddress"
                        :error-messages="noaddressError"
                        required
                        @input="$v.noaddress.$touch()"
                        @blur="$v.noaddress.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อาคาร</span><br>
                        <span class="font-weight-black">Building</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="building"
                        label="อาคาร / Building"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชั้นที่</span><br>
                        <span class="font-weight-black">Floor</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="floor"
                        label="ชั้นที่ / Floor"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เลขที่ห้อง</span><br>
                        <span class="font-weight-black">Room Number</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="noroom"
                        label="เลขที่ห้อง / Room Number"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">หมู่บ้าน</span><br>
                        <span class="font-weight-black">Village</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="mooban"
                        label="หมู่บ้าน / Village"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">หมู่ที่</span><br>
                        <span class="font-weight-black">Moo</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="no_moo"
                        label="หมู่ที่ / Moo"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ตรอก/ซอย</span><br>
                        <span class="font-weight-black">Soi</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="soi"
                        label="ตรอก/ซอย / Soi"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">แยก</span><br>
                        <span class="font-weight-black">Yaek</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="yaek"
                        label="แยก / Yaek"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ถนน</span><br>
                        <span class="font-weight-black">Road</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="road"
                        label="ถนน / Road"
                      ></v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="1" lg="1">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">โทรสาร</span><br>
                        <span class="font-weight-black">Fax</span>
                      </div>
                    </v-col>
                    <v-col xs="4" sm="4" md="3" lg="3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="fax"
                        label="โทรสาร / Fax"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  </v-row>
                </v-container>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="primary" outlined :disabled="createprogress" @click="$emit('closedialog')">ยกเลิก<br>Cancel</v-btn>
          <v-btn large class="ml-2" color="primary" :loading="createprogress" @click="fn_payment()" v-if="checktaxbtn === 1">ชำระ<br>Pay</v-btn>
          <v-btn large class="ml-2" color="primary" :loading="createprogress" @click="fn_regisbusiness()"  v-else>บันทึก<br>Save</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title :style="$vuetify.theme.dark ? 'color: white; font-size: 22px;' : 'color: black; font-size: 22px;'" class="font-weight-black">
          ที่อยู่ข้อมูลในการออก<br>ใบกำกับภาษี
          (Address<br>Information Tax Invoices)
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-form v-model="valid" class="ma-1 mr-2 ml-2">
            <v-layout row wrap justify-center>
              <v-flex xs12 sm12 md12 lg12>
                <v-container fluid>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12>
                      <p style="font-size: 20px; color:#1279BC;" class="font-weight-black">ข้อมูลผู้เสียภาษี</p>
                      <p style="font-size: 20px; color:#1279BC;" class="mt-n2 font-weight-black">(Taxpayer Information)</p>
                    </v-flex>
                  </v-layout>
                  <v-layout xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mb-3 mt-0 ml-1">
                        <span class="font-weight-black">เลขประจำตัวผู้เสียภาษี<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Tax Identification Number</span>
                      </div>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="tax_num"
                        label="เลขประจำตัวผู้เสียภาษี 13 หลัก / Identification Number"
                        maxlength="13"
                        :rules="requiredtax_num"
                        :error-messages="tax_numError"
                        required
                        @input="fn_search_taxid(), $v.tax_num.$touch()"
                        @blur="$v.tax_num.$touch()"
                        @click:clear="fn_cleardata()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters class="mt-n3">
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ประเภทนิติบุคคล(ไทย)<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Type Business(TH)</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-select
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="typebusiness"
                        :items="listtypebusiness"
                        label="ประเภทนิติบุคคล(ไทย) / Type Business(TH)"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="typebusinessError"
                        required
                        @input="fn_checktypebizth(), $v.typebusiness.$touch()"
                        @blur="$v.typebusiness.$touch()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชื่อผู้ประกอบการ(ไทย)<span style="color:red;" class="font-weight-black"> *</span></span><br>
                        <span class="font-weight-black">Business Name(TH)</span>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        clearable
                        @click:clear="clearbtnnameth()"
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_business_th"
                        label="ชื่อผู้ประกอบการ(ไทย) / Business Name(TH)"
                        :rules="requiredname"
                        :error-messages="name_business_thError"
                        required
                        @input="fn_name_th(), $v.name_business_th.$touch()"
                        @blur="$v.name_business_th.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-layout class="mt-n3" xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mb-3 mt-0 ml-1">
                        <span class="font-weight-black">ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย)</span><br>
                        <span class="font-weight-black">Tax Invoice Name(TH)</span>
                      </div>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_invoice_th"
                        label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Tax Invoice Name (TH)"
                        @click="fn_name_th"
                      ></v-text-field>
                      <!-- :rules="requiredname_th" -->
                      <!-- :error-messages="name_invoice_thError" -->
                      <!-- required -->
                      <!-- @input="$v.name_invoice_th.$touch()" -->
                      <!-- @blur="$v.name_invoice_th.$touch()" -->
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-n3" xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mt-0 ml-1 mb-3">
                        <span class="font-weight-black">ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ)</span><br>
                        <span class="font-weight-black">Tax Invoice Name(Eng)</span>
                      </div>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="name_invoice_eng"
                        label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Tax Invoice Name (Eng)"
                        @click="fn_name_eng"
                      ></v-text-field>
                      <!-- :rules="requirednameeng" -->
                      <!-- :error-messages="name_invoice_engError" -->
                      <!-- required -->
                      <!-- @input="$v.name_invoice_eng.$touch()" -->
                      <!-- @blur="$v.name_invoice_eng.$touch()" -->
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-n3" xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mb-n3 mt-0 ml-1">
                        <span class="font-weight-black">สาขา<span style="color:red;" class="font-weight-black"> *</span> / Branch</span>
                      </div>
                      <v-radio-group v-model="radios" mandatory @change="fn_radiobranch">
                        <v-radio label="สำนักงานใหญ่ (Head office)" value="สำนักงานใหญ่"></v-radio>
                        <v-radio label="สาขาอื่นๆ (Other branches)" value="สาขาอื่นๆ"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-n3" xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mt-0 ml-1 mb-3">
                        <span class="font-weight-black">รหัสสาขา / Branch No</span>
                      </div>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="branch_no"
                        :disabled="radios === 'สำนักงานใหญ่'"
                        label="รหัสสาขา / Branch No"
                        maxlength="5"
                        :rules="requiredbranch_no"
                        :error-messages="branch_noError"
                        required
                        @input="$v.branch_no.$touch()"
                        @blur="$v.branch_no.$touch()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-n3" xs12 sm12 md12 lg12>
                    <v-flex xs12 sm12 md12 lg12>
                      <div class="mb-3 mt-0 ml-1">
                        <span class="font-weight-black">ชื่อสาขา / Branch Name</span>
                      </div>
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="branch_name"
                        :disabled="radios === 'สำนักงานใหญ่'"
                        label="ชื่อสาขา / Branch Name"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="branch_nameError"
                        required
                        @input="$v.branch_name.$touch()"
                        @blur="$v.branch_name.$touch()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters class="mt-n3">
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อีเมล<span style="color:red;" class="font-weight-black"> *</span> / Email</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="email_business"
                        label="อีเมล* / Email"
                        :rules="requiredemail_biz"
                        :error-messages="email_businessError"
                        required
                        @input="$v.email_business.$touch()"
                        @blur="$v.email_business.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เบอร์มือถือ<span style="color:red;" class="font-weight-black"> *</span> / Mobile No</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="phone_number_biz"
                        label="เบอร์มือถือ* / Mobile No"
                        maxlength="10"
                        :rules="requiredTextphone"
                        :error-messages="phone_number_bizError"
                        required
                        @input="$v.phone_number_biz.$touch()"
                        @blur="$v.phone_number_biz.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เบอร์โทรศัพท์<span style="color:red;" class="font-weight-black"> *</span> / Telephone</span>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="tel_biz"
                        maxlength="10"
                        label="เบอร์โทรศัพท์* / Telephone"
                        ></v-text-field>
                        <!-- :rules="requiredTexttelephone"
                        :error-messages="tel_bizError"
                        required
                        @input="$v.tel_biz.$touch()"
                        @blur="$v.tel_biz.$touch()" -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 class="pa-1 mt-n4">
                <v-container fluid>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12 class="mb-2">
                      <p style="font-size: 20px; color:#1279BC;" class="font-weight-black">ข้อมูลที่อยู่ (Address)</p>
                    </v-flex>
                  </v-layout>
                  <v-row no-gutters>
                  <!-- <v-layout> -->
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">รหัสไปรษณีย์<span style="color:red;" class="font-weight-black"> *</span> / Post Code</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="postcode"
                        label="รหัสไปรษณีย์ / Post Code"
                        maxlength="5"
                        :rules="requiredpostcode"
                        :error-messages="postcodeError"
                        required
                        @input="$v.postcode.$touch()"
                        @blur="$v.postcode.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">จังหวัด<span style="color:red;" class="font-weight-black"> *</span> / Province</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-autocomplete
                        ref="province"
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="province"
                        :items="listprovince"
                        label="จังหวัด / Province"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="provinceError"
                        required
                        @input="$v.province.$touch()"
                        @blur="$v.province.$touch()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อำเภอ/เขต<span style="color:red;" class="font-weight-black"> *</span> / District</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-autocomplete
                        ref="district"
                        outlined
                        dense
                        clearable
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="district"
                        :items="listdistrict"
                        label="อำเภอ/เขต / District"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="districtError"
                        required
                        @input="$v.district.$touch()"
                        @blur="$v.district.$touch()"
                      ></v-autocomplete>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ตำบล/แขวง<span style="color:red;" class="font-weight-black"> *</span> / Sub-distric</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-autocomplete
                        ref="subdistrict"
                        outlined
                        dense
                        clearable
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="subdistrict"
                        :items="listsubdistrict"
                        label="ตำบล/แขวง / Sub-distric"
                        :rules="[rules.noSpecialChar]"
                        :error-messages="subdistrictError"
                        required
                        @input="$v.subdistrict.$touch()"
                        @blur="$v.subdistrict.$touch()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เลขที่อยู่<span style="color:red;" class="font-weight-black"> *</span> / Number</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="noaddress"
                        label="เลขที่อยู่ / Number"
                        :rules="requirednoaddress"
                        :error-messages="noaddressError"
                        required
                        @input="$v.noaddress.$touch()"
                        @blur="$v.noaddress.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">อาคาร / Building</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="building"
                        label="อาคาร / Building"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ชั้นที่ / Floor</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="floor"
                        label="ชั้นที่ / Floor"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">เลขที่ห้อง / Room Number</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="noroom"
                        label="เลขที่ห้อง / Room Number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">หมู่บ้าน / Village</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="mooban"
                        label="หมู่บ้าน / Village"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">หมู่ที่ / Moo</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="no_moo"
                        label="หมู่ที่ / Moo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ตรอก/ซอย / Soi</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="soi"
                        label="ตรอก/ซอย / Soi"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">แยก / Yaek</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="yaek"
                        label="แยก / Yaek"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  <!-- <v-layout> -->
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">ถนน / Road</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="road"
                        label="ถนน / Road"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <div class="mt-0 ml-1">
                        <span class="font-weight-black">โทรสาร / Fax</span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mb-n3">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0 font-weight-medium"
                        v-model="fax"
                        label="โทรสาร / Fax"
                      ></v-text-field>
                    </v-col>
                  <!-- </v-layout> -->
                  </v-row>
                </v-container>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="primary" outlined :disabled="createprogress" @click="$emit('closedialog')">ยกเลิก<br>Cancel</v-btn>
          <v-btn large class="ml-2" color="primary" :loading="createprogress" @click="fn_payment()" v-if="checktaxbtn === 1">ชำระ<br>Pay</v-btn>
          <v-btn large class="ml-2" color="primary" :loading="createprogress" @click="fn_regisbusiness()"  v-else>บันทึก<br>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2/dist/sweetalert2.js";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show","checktaxbtn"],
  mixins: [validationMixin],
  validations: {
    tax_num: { required },
    typebusiness: { required },
    name_business_th: { required },
    // name_invoice_th: { required },
    // name_invoice_eng: { required },
    radios: { required },
    branch_no: { required },
    branch_name: { required },
    email_business: { required },
    phone_number_biz: { required },
    // tel_biz: { required },
    subdistrict: { required },
    postcode: { required },
    province: { required },
    district: { required },
    noaddress: { required },
    typebusiness_eng: { required },
    // name_business_eng: { required },
  },
  data: function() {
    return {
      createprogress: false,
      valid: "",
      tax_num: "",
      typebusiness: "",
      name_business_th: "",
      name_invoice_th: "",
      name_invoice_eng: "",
      radios: "",
      branch_no: "",
      branch_name: "",
      email_business: "",
      phone_number_biz: "",
      tel_biz: "",
      typebusiness_eng: "",
      name_business_eng: "",
      listtypebusiness: [
        "ห้างหุ้นส่วนสามัญ",
        "ห้างหุ้นส่วนจำกัด",
        "บริษัทจำกัด",
        "บริษัทมหาชนจำกัด",
        "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ",
      ],
      subdistrict: "",
      listsubdistrict: [],
      postcode: "",
      province: "",
      district: "",
      listdistrict: [],
      noaddress: "",
      building: "",
      floor: "",
      noroom: "",
      mooban: "",
      no_moo: "",
      soi: "",
      yaek: "",
      road: "",
      fax: "",
      listprovince: [
        "กระบี่",
        "กรุงเทพมหานคร",
        "กาญจนบุรี",
        "กาฬสินธุ์",
        "กำแพงเพชร",
        "ขอนแก่น",
        "จันทบุรี",
        "ฉะเชิงเทรา",
        "ชลบุรี",
        "ชัยนาท",
        "ชัยภูมิ",
        "ชุมพร",
        "ตรัง",
        "ตราด",
        "ตาก",
        "นครนายก",
        "นครปฐม",
        "นครพนม",
        "นครราชสีมา",
        "นครศรีธรรมราช",
        "นครสวรรค์",
        "นนทบุรี",
        "นราธิวาส",
        "น่าน",
        "บึงกาฬ",
        "บุรีรัมย์",
        "ปทุมธานี",
        "ประจวบคีรีขันธ์",
        "ปราจีนบุรี",
        "ปัตตานี",
        "พระนครศรีอยุธยา",
        "พะเยา",
        "พังงา",
        "พัทลุง",
        "พิจิตร",
        "พิษณุโลก",
        "ภูเก็ต",
        "มหาสารคาม",
        "มุกดาหาร",
        "ยะลา",
        "ยโสธร",
        "ระนอง",
        "ระยอง",
        "ราชบุรี",
        "ร้อยเอ็ด",
        "ลพบุรี",
        "ลำปาง",
        "ลำพูน",
        "ศรีสะเกษ",
        "สกลนคร",
        "สงขลา",
        "สตูล",
        "สมุทรปราการ",
        "สมุทรสงคราม",
        "สมุทรสาคร",
        "สระบุรี",
        "สระแก้ว",
        "สิงห์บุรี",
        "สุพรรณบุรี",
        "สุราษฎร์ธานี",
        "สุรินทร์",
        "สุโขทัย",
        "หนองคาย",
        "หนองบัวลำภู",
        "อำนาจเจริญ",
        "อุดรธานี",
        "อุตรดิตถ์",
        "อุทัยธานี",
        "อุบลราชธานี",
        "อ่างทอง",
        "เชียงราย",
        "เชียงใหม่",
        "เพชรบุรี",
        "เพชรบูรณ์",
        "เลย",
        "แพร่",
        "แม่ฮ่องสอน",
      ],
      rules: {
        noSpecialChar: (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
      },
      requiredpostcode: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && value.length == 5 && this.fn_checkzibcode(value)) || "โปรดระบุรหัสไปรษณีย์ / Please fill Postcode",
      ],
      requiredtax_num: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && value.length == 13 && this.fn_checkidcard(value)) || "โปรดระบุเลขประจำตัวผู้เสียภาษี 13 หลัก / Please fill Identification Number 13 digits",
      ],
      requiredbranch_no: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && value.length == 5 && this.fn_checkidcard(value)) || "โปรดระบุรหัสสาขาเลข 5 หลัก / Please fill Branch No 5 digits",
      ],
      requiredTextphone: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && value.length >= 9 && value.length < 11 && this.fn_checkphonenumber(value)) || "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digit number",
      ],
      requiredTexttelephone: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && value.length == 10 && this.fn_checkphonenumber(value)) || "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digit number",
      ],
      requirednoaddress: [
        (value) => !!value || "โปรดระบุข้อมูล / Please fill Data",
        (value) => (value && this.fn_check_address(value)) || "โปรดระบุเลขที่อยู่ / Please fill Number",
      ],
      requiredname: [(value) => !!value || "โปรดระบุข้อมูล / Please fill Data", (value) => this.checkname(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
      requiredname_th: [(value) => !!value || "โปรดระบุข้อมูล / Please fill Data", (value) => this.checkname_th(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
      requirednameeng: [(value) => !!value || "โปรดระบุข้อมูล / Please fill Data", (value) => this.checknameeng(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
      requiredemail_biz: [(value) => !!value || "โปรดระบุข้อมูล / Please fill Data", (value) => this.fn_checkemail(value) || "โปรดระบุข้อมูล / Please fill Data"],
    };
  },
  watch: {
    typebusiness() {
      this.fn_checktypebizth();
      this.fn_name_th();
    },
    name_business_th() {
      this.fn_name_th();
    },
    subdistrict() {
      if (this.province !== "-") {
        this.fn_selectdetail_zipcode();
      }
    },
    province() {
      if (this.province !== "-") {
        this.fn_selectdetail_amphoe();
      }
    },
    district() {
      if (this.province !== "-") {
        this.fn_selectdetail_district();
      }
    },
    typebusiness_eng() {
      this.fn_checktypebizeng();
      this.fn_name_eng();
    },
    name_business_eng() {
      this.fn_name_eng();
    },
  },
  computed: {
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log(this.show);
        }
        return this.show;
      }
    },
    tax_numError() {
      const errors = [];
      if (!this.$v.tax_num.$dirty) return errors;
      !this.$v.tax_num.required && errors.push("โปรดระบุเลขประจำตัวผู้เสียภาษี / Please fill Identification Number");
      return errors;
    },
    typebusinessError() {
      const errors = [];
      if (!this.$v.typebusiness.$dirty) return errors;
      !this.$v.typebusiness.required && errors.push("โปรดระบุปะเภทนิติบุคคล / Please fill Type Business");
      return errors;
    },
    name_business_thError() {
      const errors = [];
      if (!this.$v.name_business_th.$dirty) return errors;
      !this.$v.name_business_th.required && errors.push("โปรดระบุชื่อผู้ประกอบการ / Please fill Business Name (TH)");
      return errors;
    },
    name_invoice_thError() {
      const errors = [];
      if (!this.$v.name_invoice_th.$dirty) return errors;
      !this.$v.name_invoice_th.required && errors.push("โปรดระบุชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Please fill Tax invoice name (TH)");
      return errors;
    },
    name_invoice_engError() {
      const errors = [];
      if (!this.$v.name_invoice_eng.$dirty) return errors;
      !this.$v.name_invoice_eng.required && errors.push("โปรดระบุชื่อชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Please fill Tax invoice name (Eng)");
      return errors;
    },
    branch_noError() {
      const errors = [];
      if (!this.$v.branch_no.$dirty) return errors;
      !this.$v.branch_no.required && errors.push("โปรดระบุรหัสสาขา / Please fill Branch No");
      return errors;
    },
    branch_nameError() {
      const errors = [];
      if (!this.$v.branch_name.$dirty) return errors;
      !this.$v.branch_name.required && errors.push("โปรดระบุชื่อสาขา / Please fill Branch name");
      return errors;
    },
    email_businessError() {
      const errors = [];
      if (!this.$v.email_business.$dirty) return errors;
      !this.$v.email_business.required && errors.push("โปรดระบุอีเมล / Please fill Email");
      return errors;
    },
    phone_number_bizError() {
      const errors = [];
      if (!this.$v.phone_number_biz.$dirty) return errors;
      !this.$v.phone_number_biz.required && errors.push("โปรดระบุเบอร์โทร / Please fill Mobile No");
      return errors;
    },
    // tel_bizError() {
    //   const errors = [];
    //   if (!this.$v.tel_biz.$dirty) return errors;
    //   !this.$v.tel_biz.required && errors.push("โปรดระบุเบอร์โทร / Please fill Telephone");
    //   return errors;
    // },
    subdistrictError() {
      const errors = [];
      if (!this.$v.subdistrict.$dirty) return errors;
      !this.$v.subdistrict.required && errors.push("โปรดระบุตำบล/แขวง / Please fill Sub-district");
      return errors;
    },
    postcodeError() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.required && errors.push("โปรดระบุรหัสไปรษณีย์ / Please fill Post Code");
      return errors;
    },
    provinceError() {
      const errors = [];
      if (!this.$v.province.$dirty) return errors;
      !this.$v.province.required && errors.push("โปรดระบุจังหวัด / Please fill Province");
      return errors;
    },
    districtError() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      !this.$v.district.required && errors.push("โปรดระบุอำเภอ/เขต / Please fill District");
      return errors;
    },
    noaddressError() {
      const errors = [];
      if (!this.$v.noaddress.$dirty) return errors;
      !this.$v.noaddress.required && errors.push("โปรดระบุเลขที่อยู่ / Please fill Number");
      return errors;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    fn_search_taxid() {
      console.log(this.tax_num);
      if (this.tax_num.length == 13 ) {
        // this.loaddataprogress = true;
        let i;
        let payload = {
          taxid: this.tax_num,
        };
        console.log("payload", payload);
        this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_data_business", payload)
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("dataresponse", response);

              this.noaddress = response.data.result[0].address.HouseNumber;
              this.building = response.data.result[0].address.BuildingName;
              this.floor = response.data.result[0].address.FloorNumber;
              this.noroom = response.data.result[0].address.RoomNumber;
              this.mooban = "-";
              this.no_moo = response.data.result[0].address.MooNumber;
              this.soi = response.data.result[0].address.SoiName;
              this.yaek = "-";
              this.road = response.data.result[0].address.StreetName;
              this.subdistrict = response.data.result[0].address.Thambol;
              this.district = response.data.result[0].address.Amphur;
              this.province = response.data.result[0].address.Province;
              this.postcode = response.data.result[0].address.PostCode;
              console.log("อำเภอ", this.district);
              console.log("ตำบล", this.subdistrict);

              this.typebusiness = response.data.result[0].business.business_TitleName_th;
              this.name_business_th = response.data.result[0].business.business_name;
              this.typebusiness_eng = response.data.result[0].business.business_TitleName_en;
              // this.name_business_eng = "";
              // this.name_invoice_th = response.data.result[0].business.name_on_document_th;
              // this.name_invoice_eng = "";
              this.radios = response.data.result[0].business.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
              this.branch_no = response.data.result[0].business.BranchNumber;
              this.branch_name = response.data.result[0].business.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
              this.tel_biz = "";
              // this.fn_selectdetail_amphoe();
              this.loaddataprogress = false;
            } else {
              this.fn_cleardata();
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                // title: response.data.errorMessage,
                title: "Tax ID ไม่ถูกต้อง",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            // Toast.fire({
            //   icon: "error",
            //   title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
            // });
          });
      }else{
        this.fn_cleardata();
      }
    },
    fn_cleardata(){     
      //  this.tax_num = '';
       this.typebusiness = '';
       this.name_business_th = '';
       this.name_invoice_th = '';
       this.name_invoice_eng = '';
       this.branch_no = '';
       this.branch_name = '';
       this.email_business = '';
       this.phone_number_biz = '';
       this.tel_biz = '';
       this.postcode = '';
       this.province = '';
       this.district = '';
       this.subdistrict = '';
       this.noaddress = '';
       this.building = '';
       this.floor = '';
       this.noroom = '';
       this.mooban = '';
       this.no_moo = '';
       this.soi = '';
       this.yaek = '';
       this.road = '';
       this.fax = '';

    },
    fn_checktypebizth() {
      if (this.typebusiness === "ห้างหุ้นส่วนสามัญ") {
        this.typebusiness_eng = "Ordinary Partnership";
      } else if (this.typebusiness === "ห้างหุ้นส่วนจำกัด") {
        this.typebusiness_eng = "Limited partnership";
      } else if (this.typebusiness === "บริษัทจำกัด") {
        this.typebusiness_eng = "Company limited";
      } else if (this.typebusiness === "บริษัทมหาชนจำกัด") {
        this.typebusiness_eng = "Public Limited Company";
      } else if (this.typebusiness === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
        this.typebusiness_eng = "Other";
      }
    },
    fn_name_th() {
      if (this.name_business_th !== "" || this.name_business_th !== null) {
        if (this.typebusiness === "ห้างหุ้นส่วนสามัญ") {
          this.name_invoice_th = "ห้างหุ้นส่วนสามัญ" + " " + this.name_business_th;
        } else if (this.typebusiness === "ห้างหุ้นส่วนจำกัด") {
          this.name_invoice_th = "ห้างหุ้น" + " " + this.name_business_th + " " + "จำกัด";
        } else if (this.typebusiness === "บริษัทจำกัด") {
          this.name_invoice_th = "บริษัท" + " " + this.name_business_th + " " + "จำกัด";
        } else if (this.typebusiness === "บริษัทมหาชนจำกัด") {
          this.name_invoice_th = "บริษัท" + " " + this.name_business_th + " " + "จำกัด (มหาชน)";
        } else if (this.typebusiness === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
          this.name_invoice_th = this.name_business_th;
        }
      } else {
        this.name_invoice_th = "";
      }
    },
    clearbtnnameth() {
      if (this.name_business_th === "" || this.name_business_th === null) {
        this.name_invoice_th = "";
      }
    },
    fn_name_eng() {
      if (this.name_business_eng !== "" || this.name_business_eng !== null) {
        if (this.typebusiness_eng === "Ordinary Partnership") {
          this.name_invoice_eng = this.name_business_eng + " " + "Ordinary Partnership";
        } else if (this.typebusiness_eng === "Limited partnership") {
          this.name_invoice_eng = this.name_business_eng + " " + "Limited partnership";
        } else if (this.typebusiness_eng === "Company limited") {
          this.name_invoice_eng = this.name_business_eng + " " + "Company limited";
        } else if (this.typebusiness_eng === "Public Limited Company") {
          this.name_invoice_eng = this.name_business_eng + " " + "Public Limited Company";
        } else if (this.typebusiness_eng === "Other") {
          this.name_invoice_eng = this.name_business_eng;
        }
      } else {
        this.name_invoice_eng = "";
      }
    },
    //check radio สาขา
    fn_radiobranch() {
      if (this.radios === "สำนักงานใหญ่") {
        this.branch_no = "00000";
        this.branch_name = "สำนักงานใหญ่";
      } else {
        this.branch_no = "0000";
        this.branch_name = "";
      }
    },
    fn_selectdetail_zipcode() {
      // this.postcode = "";
      let listzip;
      let payload = {
        district: this.subdistrict,
        amphoe: this.district,
        zipcode: "",
        district_code: "",
        amphoe_code: "",
        province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("dataresponse", response);
            const zip = response.data.result.find(
              (v) => v.province === this.province && v.district === this.subdistrict && v.amphoe === this.district
            );
            console.log("in", zip);
            listzip = zip.zipcode;
            this.postcode = listzip.toString();
            // listzip = response.data.result[0].zipcode
            // this.postcode = listzip.toString();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          // Toast.fire({
          //   icon: "error",
          //   title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          // });
        });
    },
    fn_selectdetail_amphoe() {
      // this.district = "";
      // this.subdistrict = "";
      // this.postcode = "";
      this.list_amphoe = [];
      let listamphoe = [];
      let listsubdistrict = [];
      let payload = {
        // district: "",
        // amphoe: "",
        // zipcode: "",
        // district_code: "",
        // amphoe_code: "",
        // province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("dataresponse", response);

            for (let i = 0; i < response.data.result.length; i++) {
              listamphoe.push(response.data.result[i].amphoe);
            }
            // console.log("listamphoe", listamphoe);
            const arr = Array.from(new Set(listamphoe));
            // console.log("arr", arr);
            this.listdistrict = arr;
            // this.list_amphoe = Array.from(new Set(listamphoe))
            // this.listdistrict =  this.list_amphoe;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_selectdetail_district() {
      // this.subdistrict = "";
      // this.postcode = "";
      this.list_subdistrict = [];
      let listsubdistrict = [];
      let payload = {
        // district: "",
        amphoe: this.district,
        // zipcode: "",
        // district_code: "",
        // amphoe_code: "",
        // province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("dataresponse", response);
            for (let i = 0; i < response.data.result.length; i++) {
              listsubdistrict.push(response.data.result[i].district);
            }
            const arr = Array.from(new Set(listsubdistrict));
            this.listsubdistrict = arr;
            // this.list_subdistrict = Array.from(new Set(listsubdistrict))
            // this.listsubdistrict =  this.list_subdistrict;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_checkidcard(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkzibcode(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_check_address(value) {
      const pattern = /^[0-9]+\W+[0-9]{1,}$/i;
      const pattern_ = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true || pattern_.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkemail(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checknameeng(value) {
       const pattern = /^[a-zA-Z\s_.-_&-]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname_th(value) {
      // const pattern = /^[ก-๏\s]+\S+$/;
      // const pattern_ = /^[ก-๏\s]+$/;
      const pattern = /^[ก-๏\W0-9]+$/;

      // if (pattern.test(value) == true || pattern_.test(value) == true) {
      if(pattern.test(value) == true){
        return true;
      } else {
        return false;
      }
    },
    checkname(value) {
      const pattern = /^[ก-๏\W0-9]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checktypebizeng() {
      if (this.typebusiness_eng === "Ordinary Partnership") {
        this.typebusiness = "ห้างหุ้นส่วนสามัญ";
      } else if (this.typebusiness_eng === "Limited partnership") {
        this.typebusiness = "ห้างหุ้นส่วนจำกัด";
      } else if (this.typebusiness_eng === "Company limited") {
        this.typebusiness = "บริษัทจำกัด";
      } else if (this.typebusiness_eng === "Public Limited Company") {
        this.typebusiness = "บริษัทมหาชนจำกัด";
      } else if (this.typebusiness_eng === "Other") {
        this.typebusiness = "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ";
      }
    },
    async fn_regisbusiness() {
      //let token = localStorage.getItem("token");
      let token = VueCookies.get("token");
      if (token === null) {
        token = sessionStorage.getItem("token")
      }
      let tokenOneid = "Bearer" + " " + token;
      this.$v.$touch();
      // console.log('!this.$v.$invalid', !this.$v.$invalid)
      // console.log('v', this.$v)
      if (!this.$v.$invalid) {
        let payload = {
          business_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
          taxid: this.tax_num,
          business_TitleName_th: this.typebusiness,
          business_name: this.name_business_th,
          business_name_en: this.name_business_eng,
          full_business_name: this.name_invoice_th,
          business_TitleName_en: this.name_invoice_eng,
          BranchNumber: this.branch_no,
          branchname: this.branch_name,
          email: this.email_business,
          telephone_number: this.phone_number_biz,
          phone_numbers: this.tel_biz,
          PostCode: this.postcode,
          Province: this.province,
          Amphur: this.district,
          Thambol: this.subdistrict,
          HouseNumber: this.noaddress,
          BuildingName: this.building,
          FloorNumber: this.floor,
          RoomNumber: this.noroom,
          MooName: this.mooban,
          MooNumber: this.no_moo,
          SoiName: this.soi,
          Separate: this.yaek,
          StreetName: this.road,
          fax_number: this.fax
        }
        console.log("payload", payload);
        await this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/insert_taxinvoic_address", payload, {
            headers: { Authorization: tokenOneid },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
              });
              console.log(this.checktaxbtn);
              if(this.checktaxbtn != 2){
                this.$emit('closedialog')
              }else{
                this.$emit('closedialognotpay')
              }
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            this.loaddataprogress = false;
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: error,
            });
            console.log(error);
          });
      }
    },
    fn_get_tax_invoice () {
      let payload = {
        business_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
      };
      // console.log("payload", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_taxinvoic_address", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log('fn_get_tax_invoice', response.data.result)
        
            this.tax_num = response.data.result.taxid;
            this.noaddress = response.data.result.HouseNumber;
            this.building = response.data.result.BuildingName;
            this.floor = response.data.result.FloorNumber;
            this.road = response.data.result.StreetName;
            this.subdistrict = response.data.result.Thambol;
            this.district = response.data.result.Amphur;
            this.province = response.data.result.Province;
            this.postcode = response.data.result.PostCode;
            this.typebusiness = response.data.result.business_TitleName_th;
            this.name_business_th = response.data.result.business_name;
            this.typebusiness_eng = response.data.result.business_TitleName_en;
            this.radios = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
            this.branch_no = response.data.result.BranchNumber;
            this.branch_name = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
            this.email_business = response.data.result.email
            this.tel_biz = response.data.result.phone_numbers
            this.mooban = response.data.result.MooName
            this.no_moo = response.data.result.MooNumber
            this.noroom = response.data.result.RoomNumber
            this.yaek = response.data.result.Separate
            this.soi = response.data.result.SoiName
            this.fax = response.data.result.fax_number
            this.name_invoice_th = response.data.result.full_business_name
            this.phone_number_biz= response.data.result.telephone_number
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            // Toast.fire({
            //   icon: "error",
            //   title: response.data.errorMessage,
            // });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_payment(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
          this.fn_regisbusiness();
          this.$emit('payment')
        
      }
    }
  },
  mounted () {
    this.fn_get_tax_invoice()
  }
}
</script>
